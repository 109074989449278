import { render, staticRenderFns } from "./Form_2.vue?vue&type=template&id=657020c7&scoped=true&"
import script from "./Form_2.vue?vue&type=script&lang=js&"
export * from "./Form_2.vue?vue&type=script&lang=js&"
import style0 from "./Form_2.vue?vue&type=style&index=0&id=657020c7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "657020c7",
  null
  
)

export default component.exports