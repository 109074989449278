<template>
  <el-form
    label-position="top"
    :model="form"
    :rules="rules"
    ref="ruleForm"
    class="w628"
  >
    <el-form-item label="店长姓名" prop="name">
      <el-input
        v-model="form.name"
        placeholder="请输入真实姓名"
        :style="form.name ? 'background: #f2f4f7;' : ''"
      ></el-input>
    </el-form-item>
    <el-form-item label="身份证照片" class="pic" prop="ID_card">
      <el-upload
        class="avatar-uploader"
        action="https://upload.realbrand.net/image/imageUpload"
        list-type="picture"
        :show-file-list="false"
        :on-success="uploadAction"
        :before-upload="beforeAvatarUpload"
        v-model="form.ID_card_front"
      >
        <div slot="default" class="upload">
          <img src="@/assets/images/uploader.png" alt="" />
          <p>请上传身份证正面</p>
        </div>
        <img
          v-if="form.ID_card_front"
          :src="form.ID_card_front"
          class="avatar"
        />
      </el-upload>
      <el-upload
        class="avatar-uploader"
        action="https://upload.realbrand.net/image/imageUpload"
        list-type="picture"
        :show-file-list="false"
        :on-success="uploadAction2"
        :before-upload="beforeAvatarUpload"
        :on-preview="handlePictureCardPreview"
      >
        <div slot="default" class="upload">
          <img src="@/assets/images/uploader.png" alt="" />
          <p>请上传身份证反面</p>
        </div>
        <img
          v-if="form.ID_card_reverse"
          :src="form.ID_card_reverse"
          class="avatar"
        />
      </el-upload>
    </el-form-item>
    <el-form-item label="有无实体门面" prop="entityStores">
      <el-radio-group v-model="form.entityStores">
        <el-radio :label="true">是</el-radio>
        <el-radio :label="false">否</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="有无冷藏冷冻设备" prop="coolers">
      <el-radio-group v-model="form.coolers">
        <el-radio :label="true">有</el-radio>
        <el-radio :label="false">无</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="社区自提点名称" prop="name2">
      <el-input
        v-model="form.name2"
        placeholder="请输入3-12个字"
        :style="form.name2 ? 'background: #f2f4f7;' : ''"
      ></el-input>
    </el-form-item>
    <!-- <el-form-item label="详细地址" prop="address" class="address">
      <el-input
        placeholder="请输入详细地址"
        suffix-icon="el-icon-location-information"
        @click.native="showMap"
        v-model="form.address"
        :style="form.address ? 'background: #f2f4f7;' : ''"
      >
      </el-input>
    </el-form-item> -->
    <el-form-item label="详细地址" prop="address" class="address">
      <el-input
        placeholder="请输入详细地址"
        suffix-icon="el-icon-location-information"
        v-model="form.address"
        :style="form.address ? 'background: #f2f4f7;' : ''"
      >
      </el-input>
    </el-form-item>
    <el-form-item label="具体门牌号" prop="houseNumber">
      <el-input
        v-model="form.houseNumber"
        placeholder="请输入例：9号楼901市"
        :style="form.houseNumber ? 'background: #f2f4f7;' : ''"
      ></el-input>
    </el-form-item>
    <el-form-item
      label="自提点照片（门头、场地照）"
      prop="storePhotos"
      class="pic"
    >
      <el-upload
        class="avatar-uploader"
        action="https://upload.realbrand.net/image/imageUpload"
        list-type="picture"
        :show-file-list="false"
        :on-success="uploadAction3"
        :before-upload="beforeAvatarUpload"
      >
        <div slot="default" class="upload">
          <img src="@/assets/images/uploader.png" alt="" />
          <p>请上传自提点照片</p>
        </div>
        <img v-if="form.storePhotos" :src="form.storePhotos" class="avatar" />
      </el-upload>
    </el-form-item>
    <div class="title">工商信息</div>
    <el-form-item label="有无营业执照" prop="businesslicense">
      <el-radio-group v-model="form.businesslicense">
        <el-radio :label="true">有</el-radio>
        <el-radio :label="false">无</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-collapse-transition>
      <div v-show="form.businesslicense">
        <el-form-item label="企业名称" prop="enterpriseName">
          <el-input
            v-model="form.enterpriseName"
            placeholder="请输入企业名称"
            :style="form.enterpriseName ? 'background: #f2f4f7;' : ''"
          ></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="SocialCreditCode">
          <el-input
            v-model="form.SocialCreditCode"
            placeholder="请输入统一社会信用代码"
            :style="form.SocialCreditCode ? 'background: #f2f4f7;' : ''"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="营业执照图片"
          prop="businessLicensePhoto"
          class="pic"
        >
          <el-upload
            class="avatar-uploader"
            action="https://upload.realbrand.net/image/imageUpload"
            list-type="picture"
            :show-file-list="false"
            :on-success="uploadAction4"
            :before-upload="beforeAvatarUpload"
          >
            <div slot="default" class="upload">
              <img src="@/assets/images/uploader.png" alt="" />
              <p>请上传营业执照图片</p>
            </div>
            <img
              v-if="form.businessLicensePhoto"
              :src="form.businessLicensePhoto"
              class="avatar"
            />
          </el-upload>
        </el-form-item>
      </div>
    </el-collapse-transition>
    <div class="title">银行卡信息</div>
    <el-form-item label="银行卡类型" prop="BankCardType">
      <el-input
        v-model="form.BankCardType"
        placeholder="储蓄卡"
        :disabled="true"
        :style="form.BankCardType ? 'background: #f2f4f7;' : ''"
      ></el-input>
    </el-form-item>
    <el-form-item label="开户银行" prop="bank">
      <el-input
        v-model="form.bank"
        placeholder="请输入开户银行名称"
        :style="form.bank ? 'background: #f2f4f7;' : ''"
      ></el-input>
    </el-form-item>
    <el-form-item label="持卡人" prop="cardholder">
      <el-input
        v-model="form.cardholder"
        placeholder="请输入持卡人姓名"
        :style="form.cardholder ? 'background: #f2f4f7;' : ''"
      ></el-input>
    </el-form-item>
    <el-form-item label="银行卡号" prop="CardNo">
      <el-input
        v-model="form.CardNo"
        placeholder="请输入卡号"
        :style="form.CardNo ? 'background: #f2f4f7;' : ''"
      ></el-input>
    </el-form-item>
    <button @click.prevent="next" type="botton">下一步</button>
  </el-form>
</template>

<script>
export default {
  name: "form-1",
  props: ["userInfo"],
  data() {
    return {
      form: {
        // name: "凯店长",
        // ID_card_front:
        //   "http://hwpicture.realbrand.net/a419a9f226b342e498fb80e3900e621d.jpg",
        // ID_card_reverse:
        //   "http://hwpicture.realbrand.net/58dfc744208c4d3398bc82969543a7a1.jpg",
        // entityStores: true,
        // coolers: true,
        // name2: "凯店铺",
        // address: "广东省深圳市福田区深南大道6029号世纪豪庭大厦",
        // houseNumber: "1号楼901",
        // storePhotos:
        //   "http://hwpicture.realbrand.net/64caa437f34747eea5ee1709dfa99601.png",
        // businesslicense: true,
        // enterpriseName: "企业名称",
        // SocialCreditCode: "统一社会信用代码",
        // businessLicensePhoto:
        //   "http://hwpicture.realbrand.net/597f8add815f4a369829888af431f39e.jpg",
        // BankCardType: "储蓄卡",
        // bank: "开户银行",
        // cardholder: "持卡人",
        // CardNo: "银行卡号",

        name: "",
        ID_card_front: "",
        ID_card_reverse: "",
        entityStores: true,
        coolers: true,
        name2: "",
        address: "",
        houseNumber: "",
        storePhotos: "",
        businesslicense: true,
        enterpriseName: "",
        SocialCreditCode: "",
        businessLicensePhoto: "",
        BankCardType: "储蓄卡",
        bank: "",
        cardholder: "",
        CardNo: "",
      },
      rules: {
        name: [{ required: true, message: "请输入真实姓名", trigger: "blur" }],
        ID_card: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.form.ID_card_front && this.form.ID_card_reverse) {
                callback();
              } else {
                callback(new Error("请上传身份证照片"));
              }
            },
            message: "请上传身份证照片",
            trigger: "blur",
          },
        ],
        name2: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value.length > 2 && value.length < 13) {
                callback();
              } else if (value === "") {
                callback(new Error("请输入社区自提点名称"));
              } else {
                callback(new Error("请输入正确的社区自提点名称"));
              }
            },

            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        houseNumber: [
          { required: true, message: "请输入具体门牌号", trigger: "blur" },
        ],
        storePhotos: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.form.storePhotos) {
                callback();
              } else {
                callback(new Error("请上传自提点照片"));
              }
            },
            message: "请上传自提点照片",
            trigger: "blur",
          },
        ],
        enterpriseName: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.form.businesslicense) {
                if (value) {
                  callback();
                } else {
                  callback(new Error("请输入企业名称"));
                }
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        SocialCreditCode: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.form.businesslicense) {
                if (value) {
                  callback();
                } else {
                  callback(new Error("请输入统一社会信用代码"));
                }
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        businessLicensePhoto: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.form.businesslicense) {
                if (this.form.businessLicensePhoto) {
                  callback();
                } else {
                  callback(new Error("请上传营业执照图片"));
                }
              } else {
                callback();
              }
            },
            message: "请上传营业执照图片",
            trigger: "blur",
          },
        ],
        BankCardType: [
          { required: true, message: "请输入银行卡类型", trigger: "blur" },
        ],
        bank: [
          { required: true, message: "请输入开户银行名称", trigger: "blur" },
        ],
        cardholder: [
          { required: true, message: "请输入持卡人姓名", trigger: "blur" },
        ],
        CardNo: [
          { required: true, message: "请输入银行卡号", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    form: {
      handler: function (val, oldVal) {
        if (!val.businesslicense) {
          val.enterpriseName = "";
          val.SocialCreditCode = "";
          val.businessLicensePhoto = "";
        }
      },
      deep: true,
    },
  },
  created() {},

  methods: {
    next() {
      // this.$emit("state", 1, true);
      // return
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.updataUser();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    updataUser() {
      let object = {
        id: sessionStorage.getItem("userId"),
        userDetailEntity: {
          frontId: this.form.ID_card_front, //身份证前照
          reverseId: this.form.ID_card_reverse, //身份证后照
          entityStore: this.form.entityStores, //有无实体门店
          refrigeration: this.form.coolers, //冷藏设备
          getGoodsName: this.form.name2, //自提点名称
          getGoodsAddress: this.form.address, //自提点地址
          detailAddress: this.form.houseNumber, //具体门牌号
          getGoodsPicture: this.form.storePhotos, //自提点门头照
          businessLicense: this.form.businesslicense, //有无营业执照
          enterpriseName: this.form.enterpriseName, //企业名称
          orgCode: this.form.SocialCreditCode, //统一社会信用代码
          businessLicensePicture: this.form.businessLicensePhoto, //营业执照图片
          bankCardType: this.form.BankCardType, //银行卡类型
          bankName: this.form.bank, //开户银行
          cardHolderName: this.form.cardholder, //持卡人
          cardCode: this.form.CardNo, //银行卡号

          id: this.userInfo.userDetailEntity.id,
          idNumber: this.userInfo.userDetailEntity.idNumber, //身份证号
          profilePicture: this.userInfo.userDetailEntity.profilePicture, //头像
          userId: this.userInfo.userDetailEntity.userId,
        },
        userEntity: {
          actualName: this.form.name, //真实姓名
          id: this.userInfo.userEntity.id, //
          name: this.userInfo.userEntity.name, //名称
          password: "", //密码
          telNum: this.userInfo.userEntity.telNum, //手机号
          userType: this.userInfo.userEntity.userType, //用户类型
          username: this.userInfo.userEntity.username, //用户名
        },
      };
      this.$store.commit("personalUser/setUser", object);
      this.$emit("state", 1, true);
    },
    handlePictureCardPreview(file) {
      console.log(file);
    },
    uploadAction(res, file, fileList) {
      if (res.code === "SUCCESS") {
        this.form.ID_card_front = res.data;
      }
    },
    uploadAction2(res, file, fileList) {
      if (res.code === "SUCCESS") {
        this.form.ID_card_reverse = res.data;
      }
    },
    uploadAction3(res, file, fileList) {
      if (res.code === "SUCCESS") {
        this.form.storePhotos = res.data;
      }
    },
    uploadAction4(res, file, fileList) {
      if (res.code === "SUCCESS") {
        this.form.businessLicensePhoto = res.data;
      }
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      // if (
      //   file.type != "image/png" &&
      //   file.type != "image/jpeg" &&
      //   file.type != "image/gif"
      // ) {
      //   this.$message.error("上传头像图片只能是 gif, jpg, jpeg, png 格式!");
      //   return false;
      // }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      //   return false;
      // }
    },

    uploadFile(item) {
      // console.log(item);
      // let userId = sessionStorage.getItem("userId");
      // let fileObj = item.file;
      // const form = new FormData(); // FormData 对象
      // form.append("file", fileObj); // 文件对象  'upload'是后台接收的参数名
      // form.append("userId", userId);
      // console.log(fileObj);
      // console.log(form);
      // this.axios(uploadImge, form, "form")
      //   .then((data) => {
      //     if (data.code == "000000") {
      //       this.$message({
      //         message: "上传成功",
      //         type: "success",
      //       });
      //       this.urlImage = data.content;
      //     }
      //   })
      //   .catch((data) => {
      //     this.$message.error("上传失败，请稍后重试");
      //   });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  ::v-deep .el-form-item {
    margin-bottom: 20px;
    .el-form-item__label {
      font-size: 14px;
      color: #333333;
      line-height: 19px;
      padding-bottom: 12px;
    }
    .el-form-item__content {
      line-height: normal;
      .el-input {
        background: #ebecf0;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        .el-input__inner {
          background-color: transparent;
          border-color: #ddd;
          border-radius: 2px;
          color: #4e4e4e;
        }
        .el-input__inner::placeholder {
          color: #bbbbbb;
        }
        .el-input__inner:focus {
          background: #f2f4f7;
        }
      }
      .el-input:hover {
        background: #f2f4f7;

        // .el-input__inner::placeholder {
        //   color: #9e9e9e;
        // }
      }
      .el-radio__inner {
        background: #fff;
        border-color: #bbbbbb;
        width: 16px;
        height: 16px;
      }
      .is-checked {
        .el-radio__inner::after {
          background: #33bb44;
          width: 8px;
          height: 8px;
        }
        .el-radio__inner {
          border-color: #33bb44;
        }
        .el-radio__label {
          color: #333333;
        }
      }
    }
  }
  ::v-deep .pic {
    .el-form-item__content {
      display: flex;
      .avatar-uploader {
        width: 140px;
        height: 96px;
        background: #f8f8f8;
        border-radius: 4px;
        border: 1px dashed #dddddd;
        margin-right: 30px;
        .el-upload {
          width: 140px;
          height: 96px;
          position: relative;
          .upload {
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            p {
              margin-top: 7px;
              height: 16px;
              font-size: 12px;
              color: #999999;
            }
          }
          .avatar {
            position: absolute;
            width: 138px;
            height: 94px;
            top: 0px;
            left: 0px;
            border-radius: 4px;
          }
        }
      }
    }
  }
  // ::v-deep .address {
  //   .el-input__inner {
  //     // cursor: pointer;
  //   }
  //   .el-input__icon {
  //     // cursor: pointer;
  //   }
  // }
  button {
    width: 100%;
    height: 44px;
    background: linear-gradient(132deg, #ff7300 0%, #fb5d20 100%);
    cursor: pointer;
    border: none;
    font-size: 16px;
    color: #ffffff;
    margin-top: 28px;
  }
  .title {
    background: #fff9f2;
    border: 1px solid #ffe5c2;
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    color: #333333;
    cursor: default;
    text-indent: 20px;
    margin-bottom: 20px;
  }
}
</style>