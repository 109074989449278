<template>
  <div id="to_register">
    <div class="shadow"></div>
    <!-- <div class="steps w628">
      <div
        class="steps_box"
        v-for="(list, index) in steps"
        :key="index"
        :style="index + 1 === 4 ? 'width:24px' : ''"
        :class="state >= index ? 'state' : ''"
      >
        <p class="number">{{ index + 1 }}</p>
        <span v-if="index + 1 != 4"></span>
        <p class="text">
          {{ list.text }}
        </p>
      </div>
    </div> -->
    <transition name="el-fade-in">
      <!-- <div class="loading" v-if="loading">
        <img
          src="@/assets/images/login_loading.png"
          alt="加载中"
          ref="loading"
        />
        <p>马上进入平台合作{{ state > 1 ? "银行" : "集群" }}开户页面</p>
      </div> -->
      <!-- <keep-alive v-else> -->
      <audit v-if="$route.name === 'audit'" :userInfo="user" />
      <keep-alive v-else>
        <form1 @state="next" :userInfo="user" v-if="state === 0" />
        <!-- <form2 @state="next" v-else-if="state === 1" />
        <form3 @state="next" v-else-if="state === 2" /> -->
        <form4 @state="next" v-else-if="state === 1" />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import Form1 from "@/components/ToRegister_Form/Form_1";
import Form2 from "@/components/ToRegister_Form/Form_2";
import Form3 from "@/components/ToRegister_Form/Form_3";
import Form4 from "@/components/ToRegister_Form/Form_4";
import Audit from "@/components/ToRegister_Form/Audit";

export default {
  name: "perfect-information",
  components: { Form1, Form2, Form3, Form4, Audit },

  data() {
    return {
      // steps: [
      //   { text: "完善店长资料" },
      //   { text: "开立工商账户" },
      //   { text: "开立银行账户" },
      //   { text: "获取PDA机具" },
      // ],
      state: 0,
      user: "",
      // loading: false,
    };
  },
  watch: {
    // loading: {
    //   handler: function (val, oldVal) {
    //     if (val) {
    //       this.loadingMethod();
    //     } else {
    //       clearInterval(this.t);
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    state: {
      handler: function (val, oldVal) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      },
    },
  },
  created() {
    // if (sessionStorage.getItem("userId")) {
    this.getUserData();
    // } else {
    //   this.$router.push("/Login");
    // }
  },
  beforeDestroy() {
    sessionStorage.removeItem("userInfo");
  },
  methods: {
    next(i, Boolean) {
      this.state = i;
      // this.loading = Boolean;
    },
    // loadingMethod() {
    //   let i = 0;
    //   this.t = setInterval(() => {
    //     i -= 8;
    //     this.$refs.loading.style = `transform: rotate(${i}deg)`;
    //   }, 30);
    //   setTimeout(() => {
    //     this.state = Number(this.state.toFixed());
    //     this.loading = false;
    //   }, 3000);
    // },
    async getUserData() {
      const data = await this.$API.personalUser.getUserData({
        object: {
          id: sessionStorage.getItem("userId"),
        },
      });
      this.user = data.data.data.userInfo;
      console.log(this.user);
      // sessionStorage.setItem(
      //   "userInfo",
      //   JSON.stringify(data.data.data.userInfo)
      // );
    },
  },
};
</script>

<style lang="scss" >
#to_register {
  padding: 80px 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .shadow {
    position: absolute;
    height: 10px;
    top: -9px;
    left: 0;
    right: 0;
    z-index: 0;
    box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.08);
  }
  .w628 {
    width: 628px;
  }
  .steps {
    display: flex;
    cursor: default;
    padding-bottom: 74px;
    .steps_box {
      width: 201px;
      position: relative;
      .number {
        width: 24px;
        height: 24px;
        line-height: 24px;
        background: #cccccc;
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        border-radius: 50%;
        z-index: 1;
        position: relative;
      }
      span {
        display: inline-block;
        width: 205px;
        height: 5px;
        background: #cccccc;
        position: absolute;
        top: 50%;
        left: 8px;
        margin-top: -2.5px;
        z-index: 0;
      }
      .text {
        font-size: 12px;
        color: #cccccc;
        position: absolute;
        width: 100px;
        text-align: center;
        left: -37px;
        top: 32px;
      }
    }
    .state {
      .number,
      span {
        background-color: #33bb44;
      }
      .text {
        color: #33bb44;
      }
    }

    // background: #33bb44;
  }
  .loading {
    width: 323px;
    height: 184px;
    box-shadow: 0px 2px 24px 1px rgba(0, 0, 0, 0.12);
    margin: 150px 0 76px 0;
    text-align: center;
    padding-top: 52px;
    img {
      transition: all 0.1s;
      // transform: rotate(10deg);
    }
    p {
      margin-top: 21px;
      font-size: 20px;
      color: #333333;
      line-height: 26px;
    }
  }
}
</style>