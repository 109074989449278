<template>
  <el-form label-position="top" :model="form" class="w628">
    <div class="message">
      <p><svg-icon icon-class="message" />欢迎使用集群开户</p>
    </div>
    <el-form-item label="法定代表人姓名">
      <el-input
        v-model="form.name"
        placeholder="请输入真实姓名"
        :style="form.name ? 'background: #f2f4f7;' : ''"
      ></el-input>
    </el-form-item>
    <el-form-item label="身份证照片" class="pic">
      <el-upload
        class="avatar-uploader"
        action="https://upload.realbrand.net/image/imageUpload"
        list-type="picture"
        :show-file-list="false"
        :on-success="uploadAction"
        :before-upload="beforeAvatarUpload"
      >
        <div slot="default" class="upload">
          <img src="@/assets/images/uploader.png" alt="" />
          <p>请上传身份证正面</p>
        </div>
        <img
          v-if="form.ID_card_front"
          :src="form.ID_card_front"
          class="avatar"
        />
      </el-upload>
      <el-upload
        class="avatar-uploader"
        action="https://upload.realbrand.net/image/imageUpload"
        list-type="picture"
        :show-file-list="false"
        :on-success="uploadAction2"
        :before-upload="beforeAvatarUpload"
        :on-preview="handlePictureCardPreview"
      >
        <div slot="default" class="upload">
          <img src="@/assets/images/uploader.png" alt="" />
          <p>请上传身份证反面</p>
        </div>
        <img
          v-if="form.ID_card_reverse"
          :src="form.ID_card_reverse"
          class="avatar"
        />
      </el-upload>
    </el-form-item>
    <el-form-item label="集群注册地址（默认)">
      <el-input
        v-model="form.address2"
        placeholder="请输入3-12个字"
        :style="form.address2 ? 'background: #f2f4f7;' : ''"
      ></el-input>
    </el-form-item>
    <el-form-item label="电子邮箱">
      <el-input
        v-model="form.email"
        placeholder="请输入电子邮箱"
        :style="form.email ? 'background: #f2f4f7;' : ''"
      ></el-input>
    </el-form-item>
    <el-form-item label="联系地址">
      <el-input
        v-model="form.address"
        placeholder="请输联系地址"
        :style="form.address ? 'background: #f2f4f7;' : ''"
      ></el-input>
    </el-form-item>
    <el-form-item label="联系手机号">
      <el-input
        v-model="form.number"
        placeholder="请输入手机号码"
        :style="form.number ? 'background: #f2f4f7;' : ''"
      ></el-input>
    </el-form-item>
    <el-form-item label="验证码">
      <el-input
        v-model="form.code"
        placeholder="请输入验证码"
        :style="form.code ? 'background: #f2f4f7;' : ''"
      >
        <template #append>
          <p>获取验证码</p>
        </template>
      </el-input>
    </el-form-item>
    <div class="button_box">
      <button class="back" @click.prevent="$emit(`state`, 0)">
        <svg-icon icon-class="ic-arrow-a" />
      </button>
      <button class="next" @click.prevent="$emit(`state`, 1.5, true)">
        下一步
      </button>
    </div>
    <div class="clause">
      <input type="checkbox" />
      <span
        >我已阅读并接受以下条款：<a href="#">《集群开户协议》</a>
        <a href="#">《隐私政策》</a></span
      >
    </div>
  </el-form>
</template>

<script>
export default {
  name: "form-2",

  data() {
    return {
      form: {
        name: "",
        address2: "韶关市曲江区马坝大道30号三楼",
        address: "",
        number: "",
        email: "",
        ID_card_front: "",
        ID_card_reverse: "",
        code: "",
      },
    };
  },

  mounted() {},

  methods: {
    handlePictureCardPreview(file) {
      console.log(file);
    },
    uploadAction(res, file, fileList) {
      if (res.code === "SUCCESS") {
        this.form.ID_card_front = res.data;
      }
    },
    uploadAction2(res, file, fileList) {
      if (res.code === "SUCCESS") {
        this.form.ID_card_reverse = res.data;
      }
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      // if (
      //   file.type != "image/png" &&
      //   file.type != "image/jpeg" &&
      //   file.type != "image/gif"
      // ) {
      //   this.$message.error("上传头像图片只能是 gif, jpg, jpeg, png 格式!");
      //   return false;
      // }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      //   return false;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  .message {
    width: 628px;
    height: 44px;
    background: #fff9f2;
    border: 1px solid #ffe5c2;
    line-height: 42px;
    padding: 0 16px;
    margin-bottom: 20px;
    p {
      font-size: 14px;
      color: #333333;
      .svg-icon {
        vertical-align: -2px;
        margin-right: 5px;
      }
    }
  }
  ::v-deep .el-form-item {
    margin-bottom: 20px;
    .el-form-item__label {
      font-size: 14px;
      color: #333333;
      line-height: 19px;
      padding-bottom: 12px;
    }
    .el-form-item__content {
      line-height: normal;
      .el-input {
        background: #ebecf0;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        .el-input__inner {
          background-color: transparent;
          border-color: #ddd;
          border-radius: 2px;
          color: #4e4e4e;
        }
        .el-input__inner::placeholder {
          color: #bbbbbb;
        }
        .el-input__inner:focus {
          background: #f2f4f7;
        }
        .el-input-group__append {
          width: 121px;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid #cbcbcb;
          border-left: none;
          text-align: center;
          padding: 0;
          p {
            cursor: pointer;
            width: 121px;
            line-height: 38px;
            color: #bbbbbb;
          }
        }
      }
      .el-input:hover {
        background: #f2f4f7;

        // .el-input__inner::placeholder {
        //   color: #9e9e9e;
        // }
      }
    }
  }
  ::v-deep .pic {
    .el-form-item__content {
      display: flex;
      .avatar-uploader {
        width: 140px;
        height: 96px;
        background: #f8f8f8;
        border-radius: 4px;
        border: 1px dashed #dddddd;
        margin-right: 30px;
        .el-upload {
          width: 140px;
          height: 96px;
          position: relative;
          .upload {
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            p {
              margin-top: 7px;
              height: 16px;
              font-size: 12px;
              color: #999999;
            }
          }
          .avatar {
            position: absolute;
            width: 138px;
            height: 94px;
            top: 0px;
            left: 0px;
            border-radius: 4px;
          }
        }
      }
    }
  }
  .button_box {
    padding-top: 28px;
    height: 72px;
    .back {
      width: 44px;
      height: 44px;
      background: #cbcbcb;
      border: none;
      float: left;
      cursor: pointer;
      color: #fff;
      transform: rotateZ(90deg);
    }
    .next {
      width: 574px;
      height: 44px;
      background: linear-gradient(132deg, #ff7300 0%, #fb5d20 100%);
      cursor: pointer;
      border: none;
      font-size: 16px;
      color: #ffffff;
      float: right;
    }
  }
  .clause {
    margin-top: 12px;
    span {
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      a {
        color: #208cf8;
      }
    }
  }
}
</style>