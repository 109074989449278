<template>
  <el-form label-position="top" :model="form" ref="ruleForm" class="w628">
    <div class="title">审核状态：{{ examineState }}</div>
    <el-form-item label="店长姓名" prop="name">
      <el-input
        :disabled="true"
        v-model="form.name"
        placeholder="请输入真实姓名"
      ></el-input>
    </el-form-item>
    <el-form-item label="身份证照片" class="pic" prop="ID_card">
      <el-image
        style="width: 140px; height: 96px"
        :src="form.ID_card_front"
        :preview-src-list="[form.ID_card_front]"
      ></el-image>
      <el-image
        style="width: 140px; height: 96px; margin-left: 30px"
        :src="form.ID_card_reverse"
        :preview-src-list="[form.ID_card_reverse]"
      ></el-image>
    </el-form-item>
    <el-form-item label="有无实体门店体" prop="entityStores">
      <el-radio-group v-model="form.entityStores" :disabled="true">
        <el-radio :label="true">是</el-radio>
        <el-radio :label="false">否</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="有无冷藏冷冻设备" prop="coolers">
      <el-radio-group v-model="form.coolers" :disabled="true">
        <el-radio :label="true">有</el-radio>
        <el-radio :label="false">无</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="社区自提点名称" prop="name2">
      <el-input :disabled="true" v-model="form.name2"></el-input>
    </el-form-item>
    <el-form-item label="详细地址" prop="address" class="address">
      <el-input :disabled="true" v-model="form.address"> </el-input>
    </el-form-item>
    <el-form-item label="具体门牌号" prop="houseNumber">
      <el-input :disabled="true" v-model="form.houseNumber"></el-input>
    </el-form-item>
    <el-form-item
      label="自提点照片（门头、场地照）"
      prop="storePhotos"
      class="pic"
    >
      <el-image
        style="width: 140px; height: 96px"
        :src="form.storePhotos"
        :preview-src-list="[form.storePhotos]"
      ></el-image>
    </el-form-item>
    <div class="title">工商信息</div>
    <el-form-item label="有无营业执照" prop="businesslicense">
      <el-radio-group v-model="form.businesslicense" :disabled="true">
        <el-radio :label="true">有</el-radio>
        <el-radio :label="false">无</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-collapse-transition>
      <div v-show="form.businesslicense">
        <el-form-item label="企业名称" prop="enterpriseName">
          <el-input :disabled="true" v-model="form.enterpriseName"></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="SocialCreditCode">
          <el-input :disabled="true" v-model="form.SocialCreditCode"></el-input>
        </el-form-item>
        <el-form-item
          label="营业执照图片"
          prop="businessLicensePhoto"
          class="pic"
        >
          <el-image
            style="width: 140px; height: 96px"
            :src="form.businessLicensePhoto"
            :preview-src-list="[form.businessLicensePhoto]"
          ></el-image>
        </el-form-item>
      </div>
    </el-collapse-transition>
    <div class="title">银行卡信息</div>
    <el-form-item label="银行卡类型" prop="BankCardType">
      <el-input :disabled="true" v-model="form.BankCardType"></el-input>
    </el-form-item>
    <el-form-item label="开户银行" prop="bank">
      <el-input :disabled="true" v-model="form.bank"></el-input>
    </el-form-item>
    <el-form-item label="持卡人" prop="cardholder">
      <el-input :disabled="true" v-model="form.cardholder"></el-input>
    </el-form-item>
    <el-form-item label="银行卡号" prop="CardNo">
      <el-input :disabled="true" v-model="form.CardNo"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "form-1",
  props: ["userInfo"],

  data() {
    return {
      form: {
        name: "",
        ID_card_front: "",
        ID_card_reverse: "",
        entityStores: true,
        coolers: true,
        name2: "",
        address: "",
        houseNumber: "",
        storePhotos: "",
        businesslicense: true,
        enterpriseName: "",
        SocialCreditCode: "",
        businessLicensePhoto: "",
        BankCardType: "",
        bank: "",
        cardholder: "",
        CardNo: "",
      },
      examineState: 0,
    };
  },
  created() {
    this.t = setInterval(() => {
      if (this.userInfo) {
        this.setInfo();
        clearInterval(this.t);
      }
    }, 100);
  },

  methods: {
    setInfo() {
      let info = this.form;
      let userD = this.userInfo.userDetailEntity;
      info.name = this.userInfo.userEntity.actualName;
      info.ID_card_front = userD.frontId;
      info.ID_card_reverse = userD.reverseId;
      info.entityStores = userD.entityStore;
      info.coolers = userD.refrigeration;
      info.name2 = userD.getGoodsName;
      info.address = userD.getGoodsAddress;
      info.houseNumber = userD.detailAddress;
      info.storePhotos = userD.getGoodsPicture;
      info.businesslicense = userD.businessLicense;
      info.enterpriseName = userD.enterpriseName;
      info.SocialCreditCode = userD.orgCode;
      info.businessLicensePhoto = userD.businessLicensePicture;
      info.BankCardType = userD.bankCardType;
      info.bank = userD.bankName;
      info.cardholder = userD.cardHolderName;
      info.CardNo = userD.cardCode;

      if (userD.examineState === 1) {
        this.examineState = "已通过";
      } else if (userD.examineState === 2) {
        this.examineState = "未通过";
      } else {
        this.examineState = "待审核";
      }
    },

    // name, //真实姓名
    // ID_card_front, //身份证前照
    // ID_card_reverse, //身份证后照
    // entityStores, //有无实体门店
    // coolers, //冷藏设备
    // name2, //自提点名称
    // address, //自提点地址
    // houseNumber, //具体门牌号
    // storePhotos, //自提点门头照
    // businesslicense, //有无营业执照
    // enterpriseName, //企业名称
    // SocialCreditCode, //统一社会信用代码
    // businessLicensePhoto, //营业执照图片
    // BankCardType, //银行卡类型
    // bank, //开户银行
    // cardholder, //持卡人
    // CardNo, //银行卡号
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  ::v-deep .el-form-item {
    margin-bottom: 20px;
    .el-form-item__label {
      font-size: 14px;
      color: #333333;
      line-height: 19px;
      padding-bottom: 12px;
    }
    .el-form-item__content {
      line-height: normal;
      .el-input {
        background: #f2f4f7;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        .el-input__inner {
          background-color: transparent;
          border-color: #ddd;
          border-radius: 2px;
          color: #4e4e4e;
        }
        .el-input__inner::placeholder {
          color: #bbbbbb;
        }
      }
      .el-input:hover {
        background: #f2f4f7;

        // .el-input__inner::placeholder {
        //   color: #9e9e9e;
        // }
      }
      .el-radio__inner {
        background: #fff;
        border-color: #bbbbbb;
        width: 16px;
        height: 16px;
      }
      .is-checked {
        .el-radio__inner::after {
          background: #33bb44;
          width: 8px;
          height: 8px;
        }
        .el-radio__inner {
          border-color: #33bb44;
        }
        .el-radio__label {
          color: #333333;
        }
      }
    }
  }
  .title {
    background: #fff9f2;
    border: 1px solid #ffe5c2;
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    color: #333333;
    cursor: default;
    text-indent: 20px;
    margin-bottom: 20px;
  }
}
</style>