<template>
  <el-form label-position="top" :model="form" class="w628">
    <div class="PDA_box">
      <img src="@/assets/images/pda.png" alt="" />
      <ul>
        <li>多维云机</li>
        <li>
          <p>品牌：<span>商米</span></p>
          <p>型号：<span>L2S</span></p>
        </li>
        <li>
          <p>
            店长价：<span style="color: #e4332b; font-weight: bold"
              >￥3000.00</span
            >
          </p>
          <p>
            每月租金：<span style="color: #e4332b; font-weight: bold"
              >￥100.00</span
            >
          </p>
        </li>
      </ul>
    </div>
    <el-form-item label="选择获取方式">
      <el-radio-group v-model="form.operations">
        <el-radio :label="0">直接购买</el-radio>
        <el-radio :label="1">租聘PDA</el-radio>
      </el-radio-group>
    </el-form-item>
    <div class="description">
      <p>说明：</p>
      <ul>
        <li>
          1、选择获取方式后，平台商务将于3个工作日内进行对接，平台联系电话：0755-33297563；
        </li>
        <li>2、店长如需退还PDA,请联系平台商务申请退还。</li>
        <li>
          3、点击浏览<a href="#/Agreement/LeasingAndServices" target="_blank"
            >《租赁与服务》</a
          >合同。
        </li>
      </ul>
    </div>
    <div class="button_box">
      <button class="back" @click.prevent="$emit(`state`, 0)">
        <!-- <svg-icon icon-class="ic-arrow-a" /> -->
        上一步
      </button>
      <button class="next" @click.prevent="updateUser">提交申请</button>
    </div>
  </el-form>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "form-2",
  data() {
    return {
      form: {
        operations: 0,
      },
    };
  },
  computed: {
    ...mapState("personalUser", ["userInfo"]),
  },
  created() {},
  methods: {
    async updateUser() {
      this.userInfo.userDetailEntity.pda = this.form.operations;
      let object = this.userInfo;
      await this.$API.personalUser
        .updateUser({ object })
        .then((res) => {
          if (res.data.code === "SUCCESS") {
            this.$router.push("/Login/AuditTips");
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error("更改失败");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  padding: 20px 0;
  ::v-deep .el-form-item {
    margin-bottom: 20px;
    .el-form-item__label {
      font-size: 14px;
      color: #333333;
      line-height: 19px;
      padding-bottom: 12px;
    }
    .el-form-item__content {
      line-height: normal;
      .el-select {
        width: 100%;
      }
      .el-input {
        background: #ebecf0;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        .el-input__inner {
          background-color: transparent;
          border-color: #ddd;
          border-radius: 2px;
          color: #4e4e4e;
        }
        .el-input__inner::placeholder {
          color: #bbbbbb;
        }
        .el-input__inner:focus {
          background: #f2f4f7;
        }
        .el-input-group__append {
          width: 121px;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid #cbcbcb;
          border-left: none;
          text-align: center;
          padding: 0;
          p {
            cursor: pointer;
            width: 121px;
            line-height: 38px;
            color: #bbbbbb;
          }
        }
      }
      .el-input:hover {
        background: #f2f4f7;

        // .el-input__inner::placeholder {
        //   color: #9e9e9e;
        // }
      }
      .is-checked {
        .el-radio__inner::after {
          background: #33bb44;
          width: 8px;
          height: 8px;
        }
        .el-radio__inner {
          border-color: #33bb44;
          background: #fff;
        }
        .el-radio__label {
          color: #333333;
        }
      }
    }
  }
  .PDA_box {
    width: 100%;
    padding: 12px;
    border: 1px solid #dddddd;
    display: flex;
    margin-bottom: 20px;
    img {
      width: 150px;
      height: 98px;
      background: #e8e8e8;
      border-radius: 2px;
      margin-right: 23px;
    }
    ul {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      li {
        display: flex;
        font-size: 14px;
        color: #333333;
        p {
          font-size: 12px;
          width: 172px;
          color: #999999;
          span {
            color: #666666;
          }
        }
      }
    }
  }
  .description {
    font-size: 14px;
    color: #333333;
    p {
      margin-bottom: 12px;
    }
    ul li {
      margin-bottom: 8px;
    }
  }
  .button_box {
    padding-top: 28px;
    height: 72px;
    display: flex;
    font-size: 16px;
    .back {
      // width: 44px;
      padding: 0 40px;
      height: 44px;
      background: #cbcbcb;
      border: none;
      float: left;
      cursor: pointer;
      color: #fff;
      // transform: rotateZ(90deg);
    }
    .next {
      margin-left: 20px;
      flex: auto;
      height: 44px;
      background: linear-gradient(132deg, #ff7300 0%, #fb5d20 100%);
      cursor: pointer;
      border: none;

      color: #ffffff;
      float: right;
    }
  }
  .clause {
    margin-top: 12px;
    span {
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      a {
        color: #208cf8;
      }
    }
  }
}
</style>